// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-entry-js": () => import("/opt/build/repo/src/templates/blog-entry.js" /* webpackChunkName: "component---src-templates-blog-entry-js" */),
  "component---src-templates-blog-list-js": () => import("/opt/build/repo/src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-pages-404-en-js": () => import("/opt/build/repo/src/pages/404.en.js" /* webpackChunkName: "component---src-pages-404-en-js" */),
  "component---src-pages-404-es-js": () => import("/opt/build/repo/src/pages/404.es.js" /* webpackChunkName: "component---src-pages-404-es-js" */),
  "component---src-pages-community-en-js": () => import("/opt/build/repo/src/pages/community.en.js" /* webpackChunkName: "component---src-pages-community-en-js" */),
  "component---src-pages-comunidad-es-js": () => import("/opt/build/repo/src/pages/comunidad.es.js" /* webpackChunkName: "component---src-pages-comunidad-es-js" */),
  "component---src-pages-estadias-es-js": () => import("/opt/build/repo/src/pages/estadias.es.js" /* webpackChunkName: "component---src-pages-estadias-es-js" */),
  "component---src-pages-estudios-es-js": () => import("/opt/build/repo/src/pages/estudios.es.js" /* webpackChunkName: "component---src-pages-estudios-es-js" */),
  "component---src-pages-games-en-js": () => import("/opt/build/repo/src/pages/games.en.js" /* webpackChunkName: "component---src-pages-games-en-js" */),
  "component---src-pages-games-field-rivals-en-js": () => import("/opt/build/repo/src/pages/games/field-rivals.en.js" /* webpackChunkName: "component---src-pages-games-field-rivals-en-js" */),
  "component---src-pages-games-field-rivals-fact-sheet-en-js": () => import("/opt/build/repo/src/pages/games/field-rivals/fact-sheet.en.js" /* webpackChunkName: "component---src-pages-games-field-rivals-fact-sheet-en-js" */),
  "component---src-pages-historia-es-js": () => import("/opt/build/repo/src/pages/historia.es.js" /* webpackChunkName: "component---src-pages-historia-es-js" */),
  "component---src-pages-index-en-js": () => import("/opt/build/repo/src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-es-js": () => import("/opt/build/repo/src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-interships-en-js": () => import("/opt/build/repo/src/pages/interships.en.js" /* webpackChunkName: "component---src-pages-interships-en-js" */),
  "component---src-pages-juegos-es-js": () => import("/opt/build/repo/src/pages/juegos.es.js" /* webpackChunkName: "component---src-pages-juegos-es-js" */),
  "component---src-pages-juegos-field-rivals-es-js": () => import("/opt/build/repo/src/pages/juegos/field-rivals.es.js" /* webpackChunkName: "component---src-pages-juegos-field-rivals-es-js" */),
  "component---src-pages-juegos-field-rivals-fact-sheet-es-js": () => import("/opt/build/repo/src/pages/juegos/field-rivals/fact-sheet.es.js" /* webpackChunkName: "component---src-pages-juegos-field-rivals-fact-sheet-es-js" */),
  "component---src-pages-story-en-js": () => import("/opt/build/repo/src/pages/story.en.js" /* webpackChunkName: "component---src-pages-story-en-js" */),
  "component---src-pages-studios-en-js": () => import("/opt/build/repo/src/pages/studios.en.js" /* webpackChunkName: "component---src-pages-studios-en-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

